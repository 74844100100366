import ContragentList from "./components/contragents/ContragentList";
import {createRoot} from 'react-dom/client';
import React, {lazy, StrictMode, Suspense} from "react";
const CatalogProductPopup = lazy(() =>
    import("./components/catalog/CatalogProductPopup"));
import "./script";
import "./subsidiary"
import "./menu"
import "./emblaSiler";
import PersonalOrderClaims from "./components/claims/PersonalOrderClaims";
import FooterPopup from "./components/footer_popup/FooterPopup";

import "./components/CallbackModal"
import "./css/index.css"

//TODO first render on click and fix correct position on first click
import './popUpNoContragent'

import "./include/Authorization";

/**
 * PERSONAL CONTRAGENTS
 * @type {HTMLElement}
 */
const nodePopup = document.getElementById('js-initial-popup');
if (nodePopup) {
    const popup = createRoot(nodePopup)
    popup.render(
        <StrictMode>
            <FooterPopup />
        </StrictMode>
    )
}

const contragentForm = document.getElementById('createContragent');

if (contragentForm) {
    createRoot(contragentForm).render(<StrictMode><ContragentList/></StrictMode>);
}

/**
 * CATALOG POPUP
 * @type {Root}
 */
const root = createRoot(document.getElementById('boxInitialPopup'));
document.addEventListener('click', function (e) {

    if (e.target.closest('.image_cart')) {
        if (e.target.closest('.initialPopup') && e.target.tagName !== 'a' && e.target.tagName !== 'IMG') {
            let item = e.target.closest('.image_cart').querySelector('.initialPopup');
            if (item) {
                const productId = item.getAttribute('data-product-id');
                const areaBuy = item.getAttribute('data-area-buy');
                const areaBuyQuantity = item.getAttribute('data-area-quantity');
                const groupedProduct = item.getAttribute('data-grouped-product');

                const setVisible = () => {
                    root.render(
                        <StrictMode>
                            <CatalogProductPopup productId={productId} areaBuyQuantity={areaBuyQuantity}
                                                 areaBuy={areaBuy}
                                                 seePopup={false} groupedProduct={groupedProduct}
                                                 setVisible={setVisible}/>
                        </StrictMode>);
                }
                root.render(
                    <StrictMode>
                        <Suspense>
                            <CatalogProductPopup productId={productId} areaBuyQuantity={areaBuyQuantity} areaBuy={areaBuy}
                                             seePopup={true} groupedProduct={groupedProduct} setVisible={setVisible}/>
                        </Suspense>
                    </StrictMode>);
            }
        }
    }
});


/**
 * CATALOG POPUP
 * @type {Root}
 */
const claims = document.querySelectorAll('.buttonOpenClaims');
// TODO - возможно заменить инициализацию
if (claims.length > 0) {
    claims.forEach((item) => {
        createRoot(item).render(
            <StrictMode>
                <PersonalOrderClaims order_id={item.getAttribute('data-order-id')}
                                     orderNumber={item.getAttribute('data-order-number')}/>
            </StrictMode>);
    })
}